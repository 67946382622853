import React from "react"

export const Nav00DataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo lnx2g30oiio-editor_css",
    children:
      "https://xsyuanxin.com/resources/website/Logo_White@3x.png",
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>首页</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
      {
        name: "item1",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>APP开发</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
      {
        name: "item2",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>小程序开发</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
      {
        name: "item3",
        className: "header0-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <p>网站开发</p>
                </span>
              ),
              name: "text",
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
}
export const Banner01DataSource = {
  wrapper: { className: "banner0 lnx32z501m-editor_css" },
  textWrapper: { className: "banner0-text-wrapper lnx2wv02g1g-editor_css" },
  title: {
    className: "banner0-title lnx2vlmnrp-editor_css",
    children: "http://xsyuanxin.com/resources/website/banner_logo.png",
  },
  content: {
    className: "banner0-content lnx2i80fs2m-editor_css",
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>科技改变世界 定制开发覆盖全行业</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  button: {
    className: "banner0-button",
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>关于我们</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
}
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: {
    playScale: 0.3,
    className: "",
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>定制开发服务</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "http://xsyuanxin.com/resources/website/APP.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>APP开发</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>
                      专注于跨平台iOS和Android双系统开发，致力于提供卓越的移动用户体验。以细致严谨的开发流程为基础，我们追求协助客户快速将他们的产品变为现实。为客户的项目提供可靠的灵感和借鉴。
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "http://xsyuanxin.com/resources/website/MinAPP.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>
                    <span>小程序开发</span>
                    <br/>
                  </p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    利用小程序的流量优势，搭建各类小程序，包含各个行业小程序平台；外卖小程序、教育小程序等，充分利用小程序又是辅助商家更好的做好生意
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "http://xsyuanxin.com/resources/website/Web.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>网站系统开发</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    面向全国进行各类网站建设，专注于宣传型网站、集团型网站建设、营销型网站建设、行业型网站建设、地产类网站建设、政府类网站建设、电商网站等
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content50DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: {
    playScale: 0.3,
    className: "",
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>开发案例</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <p>为品牌创造核心价值</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E7%94%B5%E5%95%86%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.png",
            className: "lny06v9q2fn-editor_css",
          },
          content: {
            children: (
              <span>
                <p>电商管理系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E6%9C%89%E5%A0%82%E8%AF%BE%E5%AE%B6%E6%A0%A1%E5%85%B1%E8%82%B2%E5%B9%B3%E5%8F%B0.png",
          },
          content: {
            children: (
              <span>
                <p>有堂课家校共育平台</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E5%8D%8A%E4%BE%A3%E5%AE%A0%E7%89%A9%E7%A4%BE%E5%8C%BA.png",
          },
          content: {
            children: (
              <span>
                <p>半侣宠物社区</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E5%85%85%E7%94%B5%E6%A1%A9%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0.png",
          },
          content: {
            children: (
              <span>
                <p>充电桩管理平台</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E4%BC%98%E8%B4%A2%E7%BD%91%E6%A0%A1%E7%B3%BB%E7%BB%9F.png",
          },
          content: {
            children: (
              <span>
                <p>优财网校系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%e9%9b%86%e5%9b%a2%e6%95%b0%e6%8d%ae%e4%b8%ad%e5%8f%b0%e7%ae%a1%e7%90%86%e7%b3%bb%e7%bb%9f.png",
          },
          content: {
            children: (
              <span>
                <p>集团数据中台管理系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E8%A7%81%E8%AF%86%E5%9C%88%E7%A4%BE%E4%BA%A4.png",
          },
          content: {
            children: (
              <span>
                <p>见识圈社交</p>
              </span>
            ),
          },
        },
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "http://xsyuanxin.com/resources/website/%E6%99%BA%E6%85%A7%E7%94%B5%E5%8E%82%E6%95%B0%E6%8D%AE%E5%B9%B3%E5%8F%B0.png",
          },
          content: {
            children: (
              <span>
                <p>智能用电数据平台</p>
              </span>
            ),
          },
        },
      },
    ],
  },
}
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>源昕科技的软件优势</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <p>低代码快速开发</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>独立的创意设计的团队</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      拥有独立创意设计团队，专注于互联网视觉设计、交互设计，创造极致化的用户体验。
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <p>强力硬件支持</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        坚持始终用国内外顶级网络基础产品确保客户服务品质，免去不稳定的烦恼。
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>完善的项目管控体系</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  项目进程透明化、流程高效化，以规范化的服务保障作品质量，为用户节约大量时间。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>资深的行业经验</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  涉及众多行业，有丰富的管理软件开发经验，先后为各行业的企业用户定制开发内部系统，涉及金融、医疗、房产、互联网等多个行业.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <p>良好的合作关系</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  从立项初期即时刻关注客户满意度，及时反馈和解决问题。与客户建立长久良好的合作关系，共同应对工作中面临的各项挑战.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>大数据采集分析</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <p>
                  准备非结构化软件数据以进行分析， 数据可视化，
                  将产品分析数据整理成易于消化的见解，企业大屏应用软件定制开发
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
}
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: {
    className: "footer1",
    playScale: 0.2,
  },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children:
            "http://xsyuanxin.com/resources/website/logo.png",
        },
        childWrapper: {
          children: [
            {
              name: "content0",
              children: "一家专注于软件开发的创新型软件公司，公司以JAVA、.net、C#、JavaScript开发语言为主，一群兴趣相投的年轻人致力做更好的软件，为用户提供优质的开发服务。",
            },
          ],
        },
      },
      /*{
       name: "block1",
       xs: 24,
       md: 6,
       className: "block",
       title: { children: "产品" },
       childWrapper: {
       children: [
       {
       name: "link0",
       href: "#",
       children: "产品更新记录",
       },
       {
       name: "link1",
       href: "#",
       children: "API文档",
       },
       {
       name: "link2",
       href: "#",
       children: "快速入门",
       },
       {
       name: "link3",
       href: "#",
       children: "参考指南",
       },
       ],
       },
       },*/
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "快速链接" },
        childWrapper: {
          children: [
            {
              href: "https://www.douyin.com/user/MS4wLjABAAAAthJsSZJDkszIzdeyfKc5lwTBXtNl9oH-JV8s_vPVtGAE7aobMEH_OlJ912gVt3DQ",
              name: "link0",
              target: "_blank",
              children: "抖音",
            },
            {
              href: "https://www.xiaohongshu.com/user/profile/620ac25a000000001000b5c4",
              name: "link1",
              target: "_blank",
              children: "小红书",
            },
            {
              href: "https://space.bilibili.com/3546565260085613?spm_id_from=333.337.0.0",
              name: "link2",
              target: "_blank",
              children: "B站",
            },
            {
              href: "https://weibo.com/u/7878756507?tabtype=newVideo",
              name: "link3",
              target: "_blank",
              children: "微博",
            },
          ],
        },
      },
      {
        name: "block3",
        xs: 24,
        md: 6,
        className: "block",
        title: { children: "联系我们" },
        childWrapper: {
          children: [
            {
              href: "#",
              name: "link0",
              children: "地址：广东省中山市沙溪镇云汉村富港路1号云汉国际轻纺城H栋1楼50卡",
            },
            {
              href: "#",
              name: "link1",
              children: "电话：18925340820",
            },
            {
              href: "#",
              name: "link2",
              children: "邮箱：zsyuanxin@gmail.com",
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <div>
        ©2023 <a href="http://xsyuanxin.com/">Yuanxin</a>   <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44200102445389" target={"_blank"}>粤公网安备44200102445389号</a> | <a
          href="https://beian.miit.gov.cn/" target={"_blank"}>粤ICP备2023123977号</a>
      </div>
    ),
  },
}
